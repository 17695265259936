@import 'variables';
@import 'breakpoints';

.therapist-carousel {
  background-color: #f7f4ff;

  &__container {
    @extend .center;
    padding: 30px 16px 60px;
    position: relative;
    z-index: 1;

    @include breakpoint(tablet) {
      padding: 60px 16px 120px;
    }
  }

  &__title {
    font-family: HkGroteskBold, sans-serif;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.2px;
    color: $black-color;
    margin-bottom: 40px;

    @include breakpoint(tablet) {
      margin-bottom: 60px;
    }
  }

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: $white-color;
    border: 1px solid #eef0f5;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(159, 164, 182, 0.1);
    border-radius: 8px;
    padding: 60px 20px 20px;
    position: relative;
    margin-top: 45px;
    min-height: 310px;
  }

  &__image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: -40px;
    transform: translateX(-50%);
  }

  &__card-title {
    @extend .text;
    text-align: center;
    color: $black-color;
    margin-bottom: 10px;
  }

  &__wrapper {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    min-height: 44px;
    margin-bottom: 20px;

    span {
      font-family: OpenSansRegular, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 160%;
      color: #9fa4b6;
      text-align: center;
    }
  }

  &__card-bottom,
  &__card-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__link {
    font-family: OpenSansBold, sans-serif;
    font-size: 14px;
    color: $purple-color;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__wellbee {
    width: 63px;
    height: 16px;
    margin-top: 20px;
  }

  &__twojPsycholog {
    width: 113px;
    height: 16px;
    margin-top: 20px;
  }

  &__preludium {
    width: 70px;
    height: 30px;
    margin-top: 5px;
  }

  &__button {
    background-color: #e5e1f1;
    width: 42px !important;
    height: 42px !important;
    border-radius: 50%;
    top: 60% !important;

    &:after {
      font-size: 15px !important;
      color: #7b60c4;
      font-weight: 600;
    }
  }
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-container {
  padding: 0 17px !important;
}
