@import 'breakpoints';
@import 'variables';

.help {
  position: relative;

  &__container {
    @extend .center;
    padding: 50px 16px;

    @include breakpoint(phablet) {
      padding: 70px 16px;
    }

    @include breakpoint(tablet) {
      padding: 90px 16px;
    }

    @include breakpoint(laptop) {
      padding: 110px 16px;
    }

    @include breakpoint(desktop) {
      padding: 130px 16px;
    }
  }

  &__shape {
    background: url('/public/images/Team.webp') no-repeat;
    position: absolute;
    top: -480px;
    right: 0;
    width: 696px;
    height: 1300px;
    z-index: 0;
  }

  &__title {
    @extend .sub-title;
    position: relative;
    z-index: 1;
  }

  &__wrapper {
    display: grid;
    gap: 30px;
    position: relative;
    z-index: 1;

    @include breakpoint(phablet) {
      grid-template-columns: 250px 250px;
      justify-content: center;
    }

    @include breakpoint(tablet) {
      grid-template-columns: 270px 270px;
    }
  }

  &__box {
    background: $white-color;
    border-radius: 4px;
    padding: 62px 32px;
    filter: drop-shadow(0px 24px 60px rgba(15, 24, 44, 0.15));
    text-align: center;
    text-decoration: none;
    transition: all 300ms;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__image {
    display: block;
    margin: 0 auto 76px;
    width: 100%;

    &--wellbee {
      max-width: 165px;
      max-height: 42px;
    }

    &--twojPsycholog {
      max-width: 211px;
      max-height: 42px;
    }
  }

  &__text {
    @extend .text;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 60px;
      height: 4px;
      background: #5a7184;
      opacity: 0.1;
      border-radius: 2px;
      position: absolute;
      top: -24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
