@import 'breakpoints';

.free-help {
  overflow: hidden;

  &__container {
    @extend .center;
    padding: 80px 16px;
    position: relative;
  }

  &__title {
    @extend .sub-title;
  }

  &__company-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 60px;
    justify-items: center;
    align-items: center;
    margin-bottom: 80px;

    @include breakpoint(phablet) {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 110px;
    }

    @include breakpoint(tablet) {
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 140px;
    }

    @include breakpoint(laptop) {
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 170px;
    }

    &--small {
      @include breakpoint(phablet) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 110px;
      }

      @include breakpoint(laptop) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__shape-1 {
    background: url('/public/images/Vector.webp') no-repeat;
    position: absolute;
    left: -150px;
    bottom: 150px;
    width: 500px;
    height: 500px;
    z-index: -1;
  }

  &__shape-2 {
    background: url('/public/images/Vector (1).webp') no-repeat;
    position: absolute;
    right: -150px;
    bottom: 170px;
    width: 500px;
    height: 650px;
    z-index: -1;
  }

  &__box {
    box-shadow: 0 24px 60px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 25px;
    max-width: 960px;
    margin: auto;
    background-color: $white-color;

    @include breakpoint(tablet) {
      padding: 50px;
    }
  }

  &__sub-title {
    @extend .sub-title;
    margin-bottom: 46px;
  }

  &__wrapper {
    @include breakpoint(tablet) {
      display: flex;
      flex-direction: row-reverse;
    }

    img {
      display: block;
      margin: 0 auto 30px;
      max-width: 100%;
      max-height: 100%;

      @include breakpoint(tablet) {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__list {
    list-style: disc;
    padding-left: 25px;

    li {
      @extend .text;
      margin-bottom: 20px;
      color: #0e1b48;
      line-height: 150%;

      &::marker {
        color: #4fa4ad;
        font-size: 1.3em;
      }

      &:nth-last-of-type(1) {
        margin: 0;
      }
    }
  }
}
